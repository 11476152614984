import Image from "components/Image"
import cn from "classnames"

export default function ImageCollage({ images, orientation }) {
  return (
    <div className="relative z-10 flex gap-4">
      <div className="relative basis-5/12 overflow-hidden">
        <Image
          src={images[0]?.filename}
          alt={images[0]?.alt}
          placeholder={images[0]?.blurDataURL ? "blur" : "empty"}
          blurDataURL={images[0]?.blurDataURL}
          fill
          className={cn("object-cover", {
            "rounded-bl-4xl": orientation === "content_first",
          })}
        />
      </div>
      <div className="flex basis-7/12 flex-col gap-y-4">
        <Image
          src={images[1]?.filename}
          alt={images[1]?.alt}
          placeholder={images[1]?.blurDataURL ? "blur" : "empty"}
          blurDataURL={images[1]?.blurDataURL}
          width={324}
          height={137}
          className="max-h-36 w-full object-cover"
        />
        <Image
          src={images[2]?.filename}
          alt={images[2]?.alt}
          placeholder={images[2]?.blurDataURL ? "blur" : "empty"}
          blurDataURL={images[2]?.blurDataURL}
          width={324}
          height={209}
          className={cn("max-h-52 w-full object-cover", {
            "rounded-br-4xl": orientation === "image_first",
          })}
        />
      </div>
    </div>
  )
}
